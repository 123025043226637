<template>
    <div class="text-center">
        <div class="row xs-gutters">
            <div class="col-12 mb-3 col-md-6 mb-md-0">
                <b-form-input v-model="keyword" placeholder="Upiši pojam ili zanimanje" @keydown.enter.native.prevent="submit"></b-form-input>
            </div>
            <div class="col-12 col-md-6">
                <b-form-select v-model="category" :options="categoryOptions" :class="[ 'form-control',  category == null ? 'is-default' : '' ]" @keydown.enter.native.prevent="submit">
                    <template slot="first">
                        <option :value="null">Odaberi kategoriju</option>
                    </template>
                </b-form-select>
            </div>
        </div>

        <b-button :class="'space-t'" variant="primary" @click.prevent="submit">Traži</b-button>
    </div>
</template>

<script>
    export default {
        props: {
            route: {
                type: String,
                required: true
            },
            options: {
                type: Object,
                required: true
            },
            keywordValue: {
                type: String,
                default: null
            },
            categoryValue: {
                type: String,
                default: null
            }
        },

        data() {
            return {
                keyword: null,
                category: null,
                categoryOptions: {}
            }
        },

        mounted() {
            this.categoryOptions = this.options

            if (this.keywordValue) {
                this.keyword = this.keywordValue
            }

            if (this.categoryValue) {
                this.category = this.categoryValue
            }
        },

        methods: {
            submit() {
                if (! this.keyword && ! this.category) {
                    alert('Upišite pojam ili odaberite kategoriju')
                } else if (this.keyword && this.keyword.length < 3) {
                    alert('Pojam mora sadržavati najmanje 3 znaka')
                } else {
                    let url = this.route + '?',
                        queryKeyword = '',
                        queryCategory = ''

                    if (this.keyword) {
                        queryKeyword = 'keyword=' + this.keyword
                    }

                    if (this.category) {
                        queryCategory = 'category=' + this.category
                    }

                    url += queryKeyword && queryCategory ? queryKeyword + '&' + queryCategory : (queryKeyword ? queryKeyword : queryCategory)

                    window.location.href = url
                }
            }
        }
    }
</script>
