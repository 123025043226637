<template>
    <div class="slider">
        <transition-group name="slide" mode="out-in" enter-class="slide-in" leave-class="slide-out" enter-active-class="animated slide-in-active" leave-active-class="animated slide-out-active" v-on:after-enter="afterEnter" v-on:before-leave="beforeLeave">
            <div class="slide" v-for="(slide, index) in slides" v-if="index == (active - 1)" :key="'key-' + index">
                <div class="cover" :style="setCover(slide.image)"></div>
                <div class="data">
                    <div class="data-content">
                        <div class="slide-title" v-html="slide.title"></div>
                        <p class="slide-text" v-if="slide.description" v-html="slide.description"></p>
                        <a :href="slide.btn_link" class="btn" :class="'btn-'+slide.btn_variant" v-html="slide.btn_value"></a>
                    </div>
                </div>
            </div>
        </transition-group>

        <div class="slider-nav">
            <div class="slide-index" v-html="'0' + active + ' / 0' + slidesCount"></div>
            <span class="slide-prev" @click="slide(-1)"></span>
            <span class="slide-next" @click="slide(1)"></span>
        </div>

        <div class="slider-dots">
            <a href="#" v-for="(dot, index) in slidesCount" :class="{ active: ++index === active }" @click.prevent="jump(index)">{{ index }}</a>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            items: {
                type: Array,
                required: true
            },
        },

        data() {
            return {
                slides: [],
                slidesCount: 0,
                active: 1,
                inTransition: false
            }
        },

        mounted() {
            this.slides = this.items
            this.slidesCount = this.slides.length
            this.startInterval()
        },
       
        beforeDestroy() {
            clearInterval(this.intervalId)
        },

        methods: {
            slide(amount) {
                let newActive
                const newIndex = this.active + amount

                if (this.inTransition === false ) {
                    if (newIndex > this.slidesCount) newActive = 1
                    if (newIndex === 0) newActive = this.slidesCount

                    this.active = newActive || newIndex
                }
            },

            jump(index) {
                if (this.inTransition === false ) {
                    this.active = index
                }
            },

            afterEnter(el) {
                this.inTransition = false
            },

            beforeLeave(el) {
                this.inTransition = true
            },

            setCover(img) {
                return 'background-image: url('+img+')'
            },

            startInterval() {
                this.intervalId = setInterval(() => {
                    this.slide(1)
                }, 5000)
    }
        }
    }
</script>
