window.Vue = require('vue');

import BootstrapVue from 'bootstrap-vue'

Vue.use(BootstrapVue);

Vue.component('helper-nav', require('./components/HelperNav.vue').default);
Vue.component('slider', require('./components/Slider.vue').default);
Vue.component('profession-search', require('./components/ProfessionSearch.vue').default);

const app = new Vue({
    el: '#app'
});

window.app = app
