<template>
    <section class="section no-gap shade">
        <div class="container relative px-0">
            <div class="d-flex align-items-center justify-content-between">
                <span class="nav-helper-title">Filter:</span>
                <button type="button" class="btn btn-primary nav-helper-trigger" v-html="selected.name" @click="openNav"></button>
            </div>
            <div class="nav-helper" :class="[isOpen ? 'active' : '']">
                <button class="btn btn-close" @click="closeHelper">&times;</button>

                <nav class="nav nav-pills nav-fill justify-content-center">
                    <a :href="route" class="nav-link" :class="[selected.route == route ? 'active' : '']" v-for="(info, route) in list" v-html="info.html" @click="navigateTo(route, $event)"></a>
                </nav>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        props: {
            list: {
                type: Object,
                required: true
            },
            selected: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                isOpen: false
            }
        },
        methods: {
            openNav() {
                this.isOpen = true
            },
            navigateTo(route, e) {
                if (this.selected.route == route) {
                    this.isOpen = false
                    e.preventDefault()
                    return false
                }
            },
            closeHelper() {
                this.isOpen = false
            }
        }
    }
</script>
