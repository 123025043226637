require('./bootstrap');
require('./vue');

let scrollFromTop = document.documentElement.scrollTop,
    navbar = document.querySelector('.navbar'),
    mainNav = document.querySelector('.main-menu'),
    mainNavTrigger = document.querySelector('.main-menu-trigger'),
    mainNavInTransition = false,
    scrollClass = 'is-scrolling',
    activeClass = 'active',
    submenuTriggers = document.querySelectorAll('[submenu-trigger]')

if (scrollFromTop > 0) {
    if (navbar) {
        navbar.classList.add(scrollClass)
    }
}

window.onscroll = function () {
    let el = document.scrollingElement || document.documentElement
    let scrollPosition = el.scrollTop

    if (navbar) {
        if (scrollPosition > 0 && ! navbar.classList.contains(scrollClass)) {
            navbar.classList.add(scrollClass)
        } else if (scrollPosition == 0 && navbar.classList.contains(scrollClass)) {
            navbar.classList.remove(scrollClass)
        }
    }
}

function handleNavTransitionEvent(e) {
    mainNavInTransition = false
}

if (mainNavTrigger) {
    mainNavTrigger.onclick = function () {
        if (mainNavInTransition === false) {
            mainNavInTransition = true

            if (! mainNav.classList.contains(activeClass)) {
                mainNav.classList.add(activeClass)
                mainNavTrigger.classList.add(activeClass)

                mainNav.addEventListener('transitionend', handleNavTransitionEvent, false)
            } else {
                mainNav.classList.remove(activeClass)
                mainNavTrigger.classList.remove(activeClass)

                mainNav.addEventListener('transitionend', handleNavTransitionEvent, false)
            }
        }
    }
}

for (let i in submenuTriggers) {
    if (submenuTriggers.hasOwnProperty(i)) {
        submenuTriggers[i].onclick = toggleSubmenu
    }
}

function toggleSubmenu(e) {
    e.preventDefault()

    let submenus = document.querySelectorAll('.submenu')

    for (let i = 0; i < submenus.length; i++) {
        if (submenuTriggers[i] == this) {
            continue;
        }

        submenus[i].classList.remove(activeClass)
        submenus[i].style.maxHeight = null
        submenuTriggers[i].classList.remove('submenu-active')
    }

    let submenuName = this.getAttribute('submenu-trigger'),
        submenu = document.querySelector('[data-submenu='+submenuName+']')

    this.classList.toggle('submenu-active')

    if (submenu.style.maxHeight) {
        submenu.style.maxHeight = null
        submenu.classList.remove(activeClass)
        navbar.classList.remove('active-submenu')
    } else {
        submenu.style.maxHeight = submenu.scrollHeight + 'px'
        submenu.classList.add(activeClass)
        navbar.classList.add('active-submenu')
    }
}

let map = document.querySelector('.map')

if (map) {
    window.initMap = function (lat, lng) {
        var location = {lat: lat, lng: lng}
        var gMap = new google.maps.Map(map, {zoom: 16, center: location})
        var marker = new google.maps.Marker({position: location, map: gMap})
    }
}

import ScrollReveal from 'scrollreveal'

let reveal = {
    origin: 'bottom',
    distance: 0,
    scale: 1,
    duration: 1000,
    reset: true
}

let slideUp = {
    origin: 'bottom',
    distance: '5%',
    scale: 1,
    duration: 1000,
    reset: true
}

let slideRight = {
    origin: 'left',
    distance: '5%',
    scale: 1,
    duration: 1000,
    reset: true
}

let slideRightSubtle = {
    origin: 'left',
    distance: '5px',
    scale: 1,
    duration: 1000,
    reset: true
}

let slideLeft = {
    origin: 'right',
    distance: '5%',
    scale: 1,
    duration: 1000,
    reset: true
}

ScrollReveal().reveal('.reveal', reveal);
ScrollReveal().reveal('.slide-right', slideRight);
ScrollReveal().reveal('.slide-right-subtle', slideRightSubtle);
ScrollReveal().reveal('.slide-left', slideLeft);
ScrollReveal().reveal('.slide-up', slideUp);
